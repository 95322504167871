import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import { PricesContext } from '../../lib/prices';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
import fetch from 'isomorphic-unfetch';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MdArrowForward, MdLink } from "react-icons/md";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import AssetInfoHeader from '../../components/AssetInfoHeader'
import Sol from "../../assets/svgs/sol.svg";
import { BlockFiCardAdPanel, LedgerAdPanel } from '../../components/InlineAds'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query SolanaStakingCalculatorPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

// const CalculatorGrid = styled(ContainerMain)`
//   box-sizing: border-box;
//   border: 1px solid ${ props => props.theme.theme.border.secondary};
//   background-color: ${props => props.theme.theme.bg.primary};
//   box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
//   border-radius: 8px;
//   display: grid;
//   grid-template-columns: minmax(300px, 1fr) 3fr;
//   grid-gap: 48px;
//   align-items: start;
//   margin-bottom: 48px;

//   @media (max-width: 500px) {
//     grid-template-columns: 1fr;
//     padding: 24px 24px 0 24px;
//     border: none;
//     border-radius: 0px;
//     margin: 0;
//   }

//   p, ul {
//     font-size: 0.9rem;
//     font-family: 'Inter', sans-serif;
//   }
// `

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 0px;
  /* max-width: 1200px; */

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSelectField = styled(FormControl)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }


  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }

  .MuiSelect-icon {
    color: ${props => props.theme.theme.text.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {

  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const InitialInvestmentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
`

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0px 24px 0;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding-bottom: 12px;
  display: grid;
  /* flex-wrap: wrap; */
  grid-gap: 8px;

  .label {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 4px 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 0px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .equals {
    padding: 0 12px;
  }

  @media (max-width: 500px) {
    /* margin: 0 12px; */
  }
`;

const ResultsGroup = styled.div`
  display: grid;
  align-items: center;
  padding: 4px 0;
  flex-wrap: wrap;
  justify-items: left;

  @media (max-width: 500px) {
  }
`;

const ResultsGroupTwoCol = styled.div`
  display: grid;
`

const InterestTable = styled.div`
  display: grid;
  margin: 0 auto 0 0;
  width: 300px;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  padding: 4px;
  background-color: ${props => props.theme.theme.bg.primary};
`

const InterestTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  padding: 4px 8px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }
`

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.theme.colors.blue};
  border: none;
  color: ${(props) => props.theme.theme.colors.white};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }
`

const SecondaryButton = styled.button`
  background-color: ${(props) => props.theme.theme.buttonFill.secondary};
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  /* color: ${(props) => props.theme.theme.colors.white}; */
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    /* box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1); */
  }
`

const InterestRatePanel = styled.div`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: inline-block;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  margin: 12px 0 0 0;
  width: auto;

  svg {
    padding: 0 0 0 8px;
  }
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month: </strong>
          {payload[0].payload.month}</CardText400>
        <CardText400>
          <strong>Future Value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localCryptoFutureValue.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Monthly interest earned: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestCrypto.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
}

function NumberFormatDollar(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function NumberFormatCrypto(props) {
  const { inputRef, onChange, ticker, ...other } = props;

  console.log(props)

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix={` USDC`}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StakingCalc = props => {

  const { solanaData } = useContext(PricesContext)
  // const { cosmosData } = useContext(PricesContext)

  const coinData = solanaData

  solanaData && console.log(solanaData)

  const [ timeLength, setTimeLength ] = useState(5)
  const [ principal, setPrincipal ] = useState(1000)
  const [ payment, setPayment ] = useState(0)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)
  const [ investmentRate, setInvestmentRate ] = useState(6.5)

  const initialMonthlyEarning = principal*(investmentRate/(100*12))

  // const assetPrice = ethereumPrice ? ethereumPrice[0][1].usd : 1
  const assetPrice = coinData ? coinData[27][1].current_price.usd : 1
  const assetTicker = "SOL"

  const APR = ( Math.pow( 1 + (investmentRate/100), (1/annualCompoundingPeriods) ) - 1 ) * annualCompoundingPeriods

  const rate = (Math.pow( 1 + ((APR)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValueCrypto = (principal) * Math.pow(1+rate, nper) + payment * ( (Math.pow(1+rate, nper)-1)/ rate )

  const averageAnnualEarning = (futureValueCrypto - principal - (payment*timeLength*12))/timeLength
  const overallEarning = futureValueCrypto - principal - (payment*timeLength*12)

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength * annualPaymentPeriods; i++){
      const localNper = i
      const localCryptoFutureValue = (principal) * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )

      compoundingData.push({
        month: i,
        localCryptoFutureValue: localCryptoFutureValue,
        interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      })
    }
    return compoundingData
  }

  const compoundingData = calcCompoundingData()

  console.log(compoundingData)
  console.log(principal)
  console.log(assetPrice)

  return(
    <>
      <ReactTooltip />
      <ContainerMain>
        <h1>Solana (SOL) Staking Rewards Calculator</h1>
        <H2>How much can you earn by staking your Solana (SOL)?</H2>

        <AssetInfoHeader
          name={"Solana"}
          ticker={assetTicker}
          currentPrice={assetPrice && assetPrice}
          rank={coinData && coinData[20][1]}
          marketCap={coinData && coinData[27][1].market_cap.usd}
          url={"https://solana.com"}
          icon={<Sol />}
        />

      </ContainerMain>

      <CalculatorPanel>

      <CalculatorGrid>
        <form>
          <InitialInvestmentBlock>
            <ThemedTextarea
              id="outlined-required"
              label="Initial Investment"
              value={principal}
              onChange={event => setPrincipal(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatDollar,
              }}
            />

          <ResultsPanel>
            <ResultsGroup>
              <span>= { (principal / assetPrice).toFixed(1) } {assetTicker}
                </span>

            </ResultsGroup>
          </ResultsPanel>

          </InitialInvestmentBlock>
            <ThemedTextarea
              id="outlined-required"
              label="Annual Staking Yield (APY)"
              value={investmentRate}
              onChange={event => setInvestmentRate(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatPercentage
              }}
            />

          <ThemedTextarea
            id="outlined-required"
            label="Length of time in years"
            value={timeLength}
            onChange={event => setTimeLength(event.target.value)}
            variant="outlined"
            fullWidth
          />

          <ThemedTextarea
              id="outlined-required"
              label="Recurring Monthly Deposit"
              value={payment}
              onChange={event => setPayment(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatDollar
              }}
            />

          <ResultsPanel>

          <ResultsGroup>
            <span className="label">Current { assetTicker } Price:</span>
            <div>
              <span>1 { assetTicker } = { assetPrice && <NumberFormat
                              displayType={"text"}
                              value={assetPrice.toFixed(2)}
                              thousandSeparator
                              prefix={`$`}
                            /> }
              </span>
            </div>

          </ResultsGroup>
          <ResultsGroup>
            <span className="label">End value:</span>
            <div>
              <span className="result-value">
              { futureValueCrypto && <NumberFormat
                  displayType={"text"}
                  value={futureValueCrypto.toFixed(2)}
                  thousandSeparator
                  prefix={`$`}
                /> }
              </span>
              <span> = {(futureValueCrypto / assetPrice).toFixed(3)} { assetTicker }</span>
            </div>

          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Initial monthly earning:</span>
            <div>
              <span className="result-value">
              <NumberFormat
                  displayType={"text"}
                  value={initialMonthlyEarning.toFixed(2)}
                  thousandSeparator
                  prefix={`$`}
                />
              </span>
              <span> = {(initialMonthlyEarning / assetPrice).toFixed(3)} { assetTicker }</span>
            </div>

          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Average earning per year:</span>
            <div>
              <span className="result-value">
              { futureValueCrypto && <NumberFormat
                  displayType={"text"}
                  value={ averageAnnualEarning.toFixed(2) }
                  thousandSeparator
                  prefix={`$`}
                /> }
              </span>
              <span> = {(averageAnnualEarning / assetPrice).toFixed(3)} { assetTicker }</span>
            </div>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Overall earning:</span>
            <div>
              <span className="result-value">
              { futureValueCrypto && <NumberFormat
                  displayType={"text"}
                  value={ overallEarning.toFixed(2) }
                  thousandSeparator
                  prefix={`$`}
                /> }
              </span>
              <span> = {(overallEarning / assetPrice).toFixed(3)} { assetTicker }</span>
            </div>
          </ResultsGroup>
        </ResultsPanel>

          <a href="http://r.kraken.com/151M9B" target="_blank">
            <ActionButton>
              Open a Kraken Account
              <MdArrowForward size="20px" />
            </ActionButton>
          </a>

          <InterestRatePanel>
            Current Annual { assetTicker } Staking Yield at Kraken: 6.5% APY
          </InterestRatePanel>

          <p>
            Price data powered by <a href="https://www.coingecko.com/en/api" target="_blank">CoinGecko API</a>.
          </p>

          {/* <ThemedTextareaPrimary
            id="filled-read-only-input"
            label="Investment Value"
            value={futureValue.toFixed(0)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly: true
            }}
          /> */}

        </form>

          <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 30, right: 50, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"

                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 0, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"
                  mirror
                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                  mirror
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                {/* <Legend verticalAlign="top" height={36} /> */}
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphMobile>
      </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>View Monthly Staking Rewards Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Asset Value (USD)</span>
                  <span>Asset Value ({ assetTicker })</span>
                  <span>Monthly staking reward (USD)</span>
                  <span>Monthly staking reward ({ assetTicker })</span>
                </FinancesTableRow>

                {compoundingData.map((dataPoint, index) => {
                  return(
                    <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.localCryptoFutureValue.toFixed(2)}
                            thousandSeparator
                            prefix='$'
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={(dataPoint.localCryptoFutureValue / assetPrice).toFixed(3)}
                            thousandSeparator
                            suffix={` ${assetTicker}`}
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestCrypto.toFixed(2)}
                            thousandSeparator
                            prefix='$'
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={(dataPoint.interestCrypto / assetPrice).toFixed(3)}
                            thousandSeparator
                            suffix={` ${assetTicker}`}
                          />
                        </span>
                      </FinancesTableRow>
                  )
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

      <ContainerTwoCol>
        <ContainerContent>
          <h2>What is Solana?</h2>
          <p>
            Solana is a blockchain network that is optimized for scalability, with a focus on fast transactions and high throughput.
            A few core ideas for what would later become Solana were first conceived in a 2017 white paper by Anatoly Yakovenko, a software engineer
            who previously worked at Qualcomm and Dropbox. Yakovenko went on to pair up with former colleagues at Qualcomm, Greg Fitzgerald
            and Stephen Akridge, and three others to found the company that would eventually become Solana Labs.
          </p>

          <p>
            Solana Labs raised funds through private token sales in 2018 and 2019 to fund development of the Solana protocol.
            The Solana network launched on Mainnet Beta in March of 2020. Solana Labs remains a core contributor to the Solana project
            while the Solana Foundation also helps fund ongoing development and community-building efforts.
          </p>

          <p>
            The Solana network's native cryptocurrency is the SOL token. SOL tokens can be used to pay transaction fees, interact with smart contracts, or earn a staking yield.
          </p>

          <p>
            Solana uses a Proof-of-Stake consensus mechanism to secure the network. On top of Proof-of-Stake, Solana also uses a unique
            Proof-of-History approach to determine the time of a transaction in a trustless way. This reduces transaction times and dramatically
            speeds up transaction speeds, giving the network block confirmation times of only 400 milliseconds compared to Ethereum's 15 seconds and Bitcoin's 10 minutes.
          </p>

          < LedgerAdPanel />

          <h2>What is Solana staking?</h2>

          <p>
            Since Solana operates with a Proof-of-Stake consensus mechanism, users holding SOL tokens can earn staking rewards in exchange
            for using their holdings to secure the blockchain. Staking is the process of locking up your SOL tokens to provide economic security
            to the Solana network. Through the process of staking, the Solana network ties its security to the honesty of the majority who are
            incentivized as token holders to maintain the integrity and value of the network.
          </p>

          <h2>How do I stake my SOL?</h2>

          <p>
            Solana staking works by delegating your tokens to validators who process transactions and run the network.
          </p>

          <p>
            To stake SOL, you must move your tokens into a wallet that supports staking.
            Solana provides complete documentation for staking <a href="https://docs.solana.com/staking" target="_blank">here</a>. There are two general approaches to staking your SOL:
          </p>

          <ul>
            <li>
              <strong>Using a non-custodial wallet like <a href="https://solflare.com" target="_blank"></a>SolFlare</strong>.
              With a non-custodial wallet, you have the sole control your private keys which means that you do not have to trust
              a third party with your keys. The downside to a non-custodial wallet is that you are solely responsible for not losing
              your keys and keeping them secure. When it comes to staking, with this approach you are responsible for choosing your validator, so choose carefully.
            </li>
            <li>
              <strong>Using a custodial wallet like <a href="http://r.kraken.com/151M9B" target="_blank">Kraken</a>, a crypto exchange</strong>.
              With a custodial wallet, you trust a third party to control your private keys and provide you
              access to your tokens when you wish to send or trade them. The upside to custodial wallets is that
              they are typically more convenient and your personal responsibility of safeguarding your private keys is
              lessened. With this approach, you do not have the ability to choose your validator - you must use your custodian's partner validator.
            </li>
          </ul>


          <h2></h2>

          <p></p>

        <a href="http://r.kraken.com/151M9B" target="_blank">
          <ActionButton>
            Open a Kraken Account
            <MdArrowForward size="20px" />
          </ActionButton>
        </a>

        <InterestRatePanel>
          Current Annual { assetTicker } Staking Yield at Kraken: 6.5% APY
        </InterestRatePanel>

        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>
    </>
  )
}

const SolanaStakingCalcWrapper = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'Solana (SOL) Staking Rewards Calculator'}
        description={'How much can you earn by staking your Solana (SOL)?'}
        keywords={site.keywords}
      />
      <StakingCalc />

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'solana-staking-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}


export default SolanaStakingCalcWrapper
